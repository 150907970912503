import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
import React, { useCallback, useEffect } from 'react'
import { Box, Typography, Stack, IconButton, Tooltip } from '@mui/material'
import { Close, FileOpen, Cancel } from '@mui/icons-material'

import useStyles from './styles'
import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack'

function UploadFile({
  caption,
  error = false,
  file,
  fileName,
  setFile,
  className,
  setName,
  height = 'auto',
  accept = '',
  isAllowed = false,
  logo,
  setLogo,
  handleChangeField,
  id,
  ...other
}) {
  const classes = useStyles()
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const handleDrop = useCallback(
    acceptedFiles => {
      const formData = new FormData()
      let file = acceptedFiles[0]
      if (file.size > 10000000) {
        enqueueSnackbar(dictionary.file_too_big, { variant: 'error' })
        return
      }
      formData.append('file', acceptedFiles[0], 'new')
      formData.append('type', 'normal')

      if (file) {
        if (setName) {
          setName(file.name)
        }
        setFile({
          ...file,
          fileData: file,
          file: file,
          preview: URL.createObjectURL(file) + '#toolbar=0',
          blob: formData
        })
      }
    },
    [setFile]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept
  } = useDropzone({ onDrop: handleDrop, multiple: false })

  useEffect(
    () => () => {
      if (file) {
        URL.revokeObjectURL(file.preview)
      }
    },
    [file]
  )

  return (
    <div
      className={clsx(classes.root, className)}
      {...other}
      style={{ position: 'relative' }}
    >
      <div
        className={clsx(classes.dropZone, {
          [classes.isDragActive]: isDragActive,
          [classes.isDragAccept]: isDragAccept,
          [classes.isDragReject]: isDragReject || error,
          [classes.hasFile]: file
        })}
        {...getRootProps()}
        style={{ height: height }}
      >
        <input {...getInputProps()} accept={accept} />

        <Box component="img" sx={{ height: 160 }} />
        {!file && (
          <Box
            sx={{
              ml: { md: 5 },
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {!logo && (
              <>
                <Typography
                  gutterBottom
                  variant="h5"
                  color="text.disabled"
                  align="center"
                  data-testid="Typography-5c87b4d9-6dde-4f60-9fbd-9bf9e3b0487b"
                >
                  {dictionary.drop_or_select}
                </Typography>

                {caption && (
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary' }}
                    data-testid="Typography-bd75e221-f301-4fe4-80dc-d41e1247c9bf"
                  >
                    {caption}
                  </Typography>
                )}
              </>
            )}

            {logo && (
              <>
                {logo.includes('image') ? (
                  <Box
                    component="img"
                    alt="file preview"
                    src={logo}
                    sx={{ height: 160 }}
                  />
                ) : logo.includes('.xls') ||
                  logo.includes('.csv') ||
                  logo.includes('.pdf') ||
                  logo.includes('.doc') ? (
                  <Stack alignItems="center">
                    <FileOpen />
                    <Typography
                      variant="body1"
                      data-testid="Typography-52849e01-6fc2-415f-861c-46b444128cf9"
                    >
                      {logo
                        ?.split('/')
                        ?.[logo?.split('/')?.length - 1]?.split('/')
                        ?.pop()}
                    </Typography>
                  </Stack>
                ) : (
                  <iframe
                    src={logo}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    // allowFullScreen
                    // seamless="seamless"
                  />
                )}
              </>
            )}
          </Box>
        )}
        {file && (
          <>
            {file.path?.includes('.xls') ||
            file.path?.includes('.pdf') ||
            file.path?.includes('.doc') ||
            file.fileData?.type === 'text/csv' ? (
              <Stack alignItems="center">
                <FileOpen />
                <Typography
                  variant="body1"
                  data-testid="Typography-e19594ec-ee4c-42cd-b941-ebda8753e34c"
                >
                  {file.path}
                </Typography>
              </Stack>
            ) : isAllowed && !file.fileData?.type.includes('image') ? (
              <>
                <iframe
                  src={file.preview ? file.preview : file}
                  height="100%"
                  width="100% !important"
                  allowFullScreen
                  seamless="seamless"
                />
              </>
            ) : isAllowed && file.fileData?.type.includes('image') ? (
              <>
                <Box
                  component="img"
                  alt="file preview"
                  src={file.preview ? file.preview : file}
                  sx={{
                    top: 8,
                    borderRadius: 1,
                    objectFit: 'cover',
                    position: 'absolute',
                    width: 'calc(100% - 16px)',
                    height: 'calc(100% - 16px)',
                    maxHeight: '100%',
                    maxWidth: '100%'
                  }}
                />
              </>
            ) : (
              <Stack alignItems="center">
                <Cancel />
                <Typography
                  variant="body1"
                  data-testid="Typography-347075c1-8cf3-486d-9d8a-7f303caee17e"
                >
                  {dictionary.import_file_format_error}
                </Typography>
              </Stack>
            )}
          </>
        )}
      </div>
      <Box
        sx={{
          position: 'absolute',
          bottom: t => t.spacing(2),
          right: t => t.spacing(2)
        }}
      >
        <Tooltip title={dictionary.clear}>
          <IconButton
            onClick={() => {
              setFile(null)
              logo && setLogo(null)
              handleChangeField && handleChangeField('', id, 'file')
            }}
            data-testid="IconButton-5f92cc0f-4e5c-48a5-9474-251a5c89d714"
          >
            <Close color="error" />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  )
}

export default UploadFile
